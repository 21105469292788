@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?91cuo8');
  src:  url('fonts/icomoon.eot?91cuo8#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?91cuo8') format('truetype'),
    url('fonts/icomoon.woff?91cuo8') format('woff'),
    url('fonts/icomoon.svg?91cuo8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #a2a5b9;
  font-size: 50px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.stepperConnector{
  position: absolute;
  top: 20px;
  /* color: gray; */
}

[class^="makeStyles-active-"], [class*=" makeStyles-active-"] > [class^="icon-"], [class^="makeStyles-active-"], [class*=" makeStyles-completed-"] > [class^="icon-"], [class*=" makeStyles-active-"] > [class^="icon-"]::before, [class*=" makeStyles-completed-"] > [class^="icon-"]::before{
  color: #F92C78;
}

[class^="small"], [class*=" small"] > [class^="icon-"], [class*=" icon-"], [class^="small"], [class*=" small"] > [class^="icon-"], [class*=" icon-"]{
  font-size: 20px;
}

.icon-polaroid:before {
  content: "\e912";
}
.icon-photo:before {
  content: "\e913";
}
.icon-gift:before {
  content: "\e914";
}
.icon-sale:before {
  content: "\e915";
}
.icon-premium-quality:before {
  content: "\e916";
}
.icon-rate:before {
  content: "\e917";
}
.icon-reward:before {
  content: "\e918";
}
.icon-partner:before {
  content: "\e919";
}
.icon-list:before {
  content: "\e91a";
}
.icon-plus:before {
  content: "\e91b";
}
.icon-art:before {
  content: "\e91c";
}
.icon-money:before {
  content: "\e900";
}
.icon-business-and-finance:before {
  content: "\e901";
}
.icon-business-and-finance-1:before {
  content: "\e902";
}
.icon-calendar:before {
  content: "\e903";
}
.icon-null1:before {
  content: "\e904";
}
.icon-glamour:before {
  content: "\e905";
}
.icon-controls-1:before {
  content: "\e906";
  color: #a2a5b9;
}
.icon-gift-box-2:before {
  content: "\e908";
  color: #a2a5b9;
}
.icon-image:before {
  content: "\e909";
  color: #a2a5b9;
}
.icon-list-1:before {
  content: "\e90a";
  color: #a2a5b9;
}
.icon-security:before {
  content: "\e90b";
  color: #a2a5b9;
}
.icon-check-mark:before {
  content: "\e907";
}
.icon-chevron:before {
  content: "\e90c";
}
.icon-family-tree:before {
  content: "\e90d";
}
.icon-list1:before {
  content: "\e90e";
}
.icon-mockup:before {
  content: "\e90f";
}
.icon-parameters:before {
  content: "\e911";
}
.icon-trophy:before {
  content: "\e910";
}
